import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selector", "fileInput", "purgeInput", "fileName", "fileNameWrapper", "image", "imageWrapper"]
  static classes = ["hidden", "selectorVisible", "selectorActive", "selectorInactive", "fileNameVisible", "imageVisible"]
  static values = { emptyImageSrc: String }

  connect() {
    this.dragCounter = 0;

    this.fileTypes = this.fileInputTarget.accept.split(",").map(x => x.trim()).filter(x => x.length > 0)
  }

  dragoverDoc(event) { event.preventDefault() }
  dragleaveDoc(event) { event.preventDefault() }
  dropDoc(event) { event.preventDefault() }

  dragenterSelector(event) {
    event.preventDefault()

    this.dragCounter++
    this._toggleSelectorActivity(true)
  }

  dragleaveSelector(event) {
    event.preventDefault()

    this.dragCounter--
    if (this.dragCounter === 0) this._toggleSelectorActivity(false)
  }

  dropSelector(event) {
    event.preventDefault()

    this.dragCounter = 0
    this._toggleSelectorActivity(false)

    if (this._isDroppable(event.dataTransfer.files)) {
      this.fileInputTarget.files = event.dataTransfer.files
      this.fileChange()
    }
  }

  openSelect() {
    this.fileInputTarget.click()
  }

  fileChange() {
    const file = this.fileInputTarget.files[0]

    if (file) {
      if (this.hasPurgeInputTarget) this.purgeInputTarget.checked = false
      if (this.hasImageTarget) this.imageTarget.src = URL.createObjectURL(file)
      if (this.hasFileNameTarget) this.fileNameTarget.innerHTML = file.name

      this._toggleSelectorVisibility(false)
    }

    this.dispatch("change", { detail: { file: file } })
  }

  purgeFile() {
    this._toggleSelectorVisibility(true)

    this.fileInputTarget.value = null

    if (this.hasPurgeInputTarget) this.purgeInputTarget.checked = true
    if (this.hasImageTarget) this.imageTarget.src = this.emptyImageSrcValue
    if (this.hasfileNameTarget) this.fileNameTarget = "-"
  }

  _toggleSelectorVisibility(visible) {
    if (visible) {
      this.selectorTarget.classList.remove(...this.hiddenClasses)
      this.selectorTarget.classList.add(...this.selectorVisibleClasses)

      if (this.hasImageWrapperTarget) {
        this.imageWrapperTarget.classList.add(...this.hiddenClasses)
        this.imageWrapperTarget.classList.remove(...this.imageVisibleClasses)
      }

      if (this.hasFileNameWrapperTarget) {
        this.fileNameWrapperTarget.classList.add(...this.hiddenClasses)
        this.fileNameWrapperTarget.classList.remove(...this.fileNameVisibleClasses)
      }

    } else {
      this.selectorTarget.classList.add(...this.hiddenClasses)
      this.selectorTarget.classList.remove(...this.selectorVisibleClasses)

      if (this.hasImageWrapperTarget) {
        this.imageWrapperTarget.classList.remove(...this.hiddenClasses)
        this.imageWrapperTarget.classList.add(...this.imageVisibleClasses)
      }

      if (this.hasFileNameWrapperTarget) {
        this.fileNameWrapperTarget.classList.remove(...this.hiddenClasses)
        this.fileNameWrapperTarget.classList.add(...this.fileNameVisibleClasses)
      }
    }
  }

  _toggleSelectorActivity(active) {
    if (active) {
      this.selectorTarget.classList.add(...this.selectorActiveClasses)
      this.selectorTarget.classList.remove(...this.selectorInactiveClasses)
    } else {
      this.selectorTarget.classList.remove(...this.selectorActiveClasses)
      this.selectorTarget.classList.add(...this.selectorInactiveClasses)
    }
  }

  _isDroppable(files) {
    if (files.length !== 1) return false

    const file = files[0]

    if (this.fileTypes.length > 0) {
      const isAllowedType = this.fileTypes.includes(file.type) ||
        this.fileTypes.includes(`${file.type.split('/')[0]}/*`)

      if(!isAllowedType) return false
    }

    return true
  }
}

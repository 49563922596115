import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nameInput"]

  updateName(dropzoneEvent) {
    const file = dropzoneEvent.detail.file

    if (file && this.hasNameInputTarget) {
      this.nameInputTarget.value = this._createTitle(file.name)
    }
  }

  _createTitle(fileName) {
    if (fileName === null || fileName === '') return fileName

    let title = fileName.toString()

    title = title.replace(/\.[^.]+$/, "") // Remove file extension.
    title = title.replace(/[-_]+/g, " ") // Replace all dashes and underscores with a space.
    title = title.charAt(0).toUpperCase() + title.slice(1) // Uppercase first letter.

    return title
  }
}
